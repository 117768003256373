// extracted by mini-css-extract-plugin
export var Ready = "Boost-module--Ready--a9e22";
export var bannerBtn = "Boost-module--bannerBtn--b9743";
export var btn_white2_border = "Boost-module--btn_white2_border--c7796";
export var card = "Boost-module--card--e2954";
export var cardWrapper = "Boost-module--cardWrapper--54dd9";
export var con = "Boost-module--con--3cfde";
export var description = "Boost-module--description--40274";
export var description2 = "Boost-module--description2--09c2f";
export var heading = "Boost-module--heading--a10ec";
export var subservice = "Boost-module--subservice--54306";