import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import * as styles from "./TechStacksMobile.module.scss"
import "./TechSaasMobile.scss"
const TechStacksMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Frontend",
      tech: [
        {
          name: "Next.js",
        },
        {
          name: "React Framework",
        },
        {
          name: "Angular",
        },
        {
          name: "HTML5",
        },
        {
          name: "Nuxtjs",
        },
        {
          name: "Vuejs framework",
        },
        {
          name: "MeteorJs",
        },
        {
          name: "Gatsby",
        },
        {
          name: "Nodemailer",
        },
        {
          name: "Lodash",
        },
        {
          name: "Winston",
        },
        {
          name: "Redux",
        },
        {
          name: "Axios/ Fetch",
        },
        {
          name: "React-router",
        },
      ],
    },
    {
      title: "Backend",
      tech: [
        {
          name: "MeteorJs",
        },
        {
          name: "Net dev",
        },
        {
          name: "Node.js",
        },
        {
          name: "Fastify",
        },
        {
          name: "Express.js",
        },
      ],
    },
    {
      title: "Frameworks",
      tech: [
        {
          name: "AureliaJs",
        },
        {
          name: "Polymer.js",
        },
        {
          name: "MithrilJS",
        },
        {
          name: "Axios",
        },
        {
          name: "TensorFlow.js",
        },
        {
          name: "TailwindCSS",
        },
        {
          name: "Code Ignitor",
        },
        {
          name: "CakePHP",
        },
        {
          name: "Nest.js",
        },
      ],
    },
    {
      title: "Tools",
      tech: [
        {
          name: "Axios",
        },
        {
          name: "ESLint",
        },
        {
          name: "Husky",
        },
        {
          name: "Less",
        },
        {
          name: "Grunt",
        },
        {
          name: "Prettier",
        },
        {
          name: "Webpack",
        },
        {
          name: "Postman",
        },
      ],
    },
    {
      title: "Testing",
      tech: [
        {
          name: "Jasmine",
        },
        {
          name: "Mocha",
        },
        {
          name: "Qunit",
        },
        {
          name: "Unit.js",
        },
        {
          name: "Protractor",
        },
        {
          name: "Karma",
        },
        {
          name: "Jest",
        },
        {
          name: "Selenium",
        },
        {
          name: "Gradle",
        },
      ],
    },
    {
      title: "Platforms",
      tech: [
        {
          name: "PowerBI",
        },
        {
          name: "Zoho",
        },
        {
          name: "MS Dynamic CRM",
        },
      ],
    },
  ]
  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  return (
    <div className={styles.techMobileSaasDev}>
      <div className={styles.globalTechMobile}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`techAccordSaaS ${
                      e?.title === activeAccordion
                        ? "active-accordion"
                        : "second-accordion"
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      e?.title !== activeAccordion ? { marginTop: "24px" } : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`cardBody`}>
                      <div className={styles.techMobile}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <h3
                              className={
                                i === 1
                                  ? styles.name1
                                  : i === 3
                                  ? styles.name3
                                  : i === 5
                                  ? styles.name5
                                  : i === 7
                                  ? styles.name7
                                  : i === 9
                                  ? styles.name9
                                  : styles.name
                              }
                            >
                              {el?.name}
                            </h3>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default TechStacksMobile
