// extracted by mini-css-extract-plugin
export var bannerBtn = "Team-module--bannerBtn--3ec88";
export var cards1 = "Team-module--cards1--29e1b";
export var cards2 = "Team-module--cards2--93857";
export var cards3 = "Team-module--cards3--31b73";
export var dec = "Team-module--dec--0e067";
export var description = "Team-module--description--d09f9";
export var developer = "Team-module--developer--6ef10";
export var getBtn = "Team-module--getBtn--5edc9";
export var heading = "Team-module--heading--a923e";
export var review = "Team-module--review--9dc2e";
export var rightStack = "Team-module--rightStack--4c9be";
export var stack = "Team-module--stack--1d0c0";
export var team = "Team-module--team--9f8e8";