import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import tick from "../../images/hireMernStack/tick.png"
import * as styles from "./Banner.module.scss"
import SaasForm from "./saasDeveloperForm"

const Banner = ({ strapiData, pageName }) => {
  const cardData = [
    {
      img: tick,
      dec: "97% Customer Success Rate",
    },
    {
      img: tick,
      dec: "10+ Years Of Experience",
    },
    {
      img: tick,
      dec: "Free Consultation",
    },
    {
      img: tick,
      dec: "300+ Projects",
    },
  ]

  return (
    <div id="saasBanner" className={styles.SaaSBanner}>
      <Container>
        <Row>
          <Col xl={6} md={12} lg={6}>
            <div className={styles.heading}>
              <h1
                className={styles.bannerHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
              <p></p>
            </div>
            <div className={`${styles.storyIos} d-flex`}>
              {cardData?.map((el, i) => (
                <div className={styles.success} key={i}>
                  <img src={tick} alt="tick" />
                  <p>{el?.dec}</p>
                </div>
              ))}
            </div>
          </Col>
          <Col xl={6} md={12} lg={6} className={styles.form}>
            <SaasForm pageName={pageName} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
