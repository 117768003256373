// extracted by mini-css-extract-plugin
export var btn = "Expertise-module--btn--3fe29";
export var cards = "Expertise-module--cards--47e3d";
export var description = "Expertise-module--description--7e2d5";
export var hire = "Expertise-module--hire--5e859";
export var hireCyber = "Expertise-module--hireCyber--d6ca7";
export var hireImg = "Expertise-module--hireImg--a8506";
export var subTitle = "Expertise-module--subTitle--40d16";
export var talent = "Expertise-module--talent--2eba7";
export var teamBtn = "Expertise-module--teamBtn--a925e";
export var text = "Expertise-module--text--54660";
export var tick = "Expertise-module--tick--763f8";