import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import ahmed from "../../images/hire-saas/ahmed.svg"
import zeeshan from "../../images/hire-saas/zeeshan.svg"
import zohaib from "../../images/hire-saas/zohaib.svg"
import stars from "../../images/hire-saas/stars.svg"
import * as styles from "./Team.module.scss"

const cardData = [
  {
    image: ahmed,
    title: "Ahmed Wahaj",
    dec: "Ahmed is skilled in creating scalable SaaS solutions that are guaranteed to increase business efficiency.Proficient in dynamic languages, he ensures the delivery of user-centric applications.",
    btn: "Hire Ahmed Wahaj",
    experiece: "5 years of experience",
    tech: [
      {
        stack: "HTML",
      },
      {
        stack: "CSS",
      },
      {
        stack: "Angular",
      },
      {
        stack: "Vue.js",
      },
      {
        stack: "React",
      },
    ],
  },
  {
    image: zohaib,
    title: "Zohaib Javed",
    dec: "Zohaib crafts cutting-edge solutions with a passion for efficiency and user satisfaction. He is a true expert in Agile methodologies, transforming ideas into top-tier, scalable software solutions.",
    btn: "Hire Zohaib Javed",
    experiece: "4 years of experience",
    tech: [
      {
        stack: "Node. js",
      },
      {
        stack: "PHP",
      },
      {
        stack: "C#",
      },
      {
        stack: "Agile methodologies",
      },
    ],
  },
  {
    image: zeeshan,
    title: "Zeeshan Khan",
    dec: "Zeeshan is a seasoned SaaS developer with a track record of providing complex, high-performance software solutions. His strategic acumen has helped companies navigate the complex landscape of technological problems.",
    btn: "Hire Zeeshan Khan",
    experiece: "6 years of experience",
    tech: [
      {
        stack: "Ruby",
      },
      {
        stack: "Python",
      },
      {
        stack: "Django",
      },
      {
        stack: "Flask",
      },
    ],
  },
]

const Banner = ({ strapiData }) => {
  return (
    <div className={styles.team}>
      <Container>
        <div className={styles.heading}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <Row className="gap-30 justify-content-center">
          {cardData &&
            cardData?.map((e, i) => (
              <Col lg={4} md={6} sm={12} sx={12} className="d-flex">
                <div
                  className={
                    i === 0
                      ? styles.cards1
                      : i === 1
                      ? styles.cards2
                      : i === 2
                      ? styles.cards3
                      : ""
                  }
                >
                  <img src={e?.image} className={styles.developer} alt="icon" />
                  <h3>{e?.title}</h3>
                  <p className={styles.dec}>{e?.experiece}</p>
                  <span>
                    {e?.tech &&
                      e?.tech?.map((el, i) => (
                        <p
                          className={i === 4 ? styles.rightStack : styles.stack}
                        >
                          {el?.stack}
                        </p>
                      ))}
                  </span>
                  <hr />
                  <p className={styles.dec}>{e?.dec}</p>
                  <img src={stars} alt="icon" />
                  <p className={styles.review}>
                    <b style={{ color: "black" }}>5.0 Stars </b>Reviews
                  </p>
                  <div className={`${styles.bannerBtn}`}>
                    <Link to="#saasBanner" className={styles.getBtn}>
                      {e?.btn}
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
        <div className={`${styles.bannerBtn}`}>
          {strapiData[1]?.buttons[0] && (
            <Link to="#saasBanner" className={styles.getBtn}>
              {strapiData[1]?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Banner
