// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--ab96f";
export var clientDots2 = "Testimonials-module--clientDots2--4a6b6";
export var content = "Testimonials-module--content--04571";
export var dots3 = "Testimonials-module--dots3--474d2";
export var hireArrow = "Testimonials-module--hireArrow--024cb";
export var iconContainer = "Testimonials-module--iconContainer--4e61d";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--d62ed";
export var imgBtn = "Testimonials-module--imgBtn--9162f";
export var person = "Testimonials-module--person--e6d84";
export var personBg = "Testimonials-module--personBg--e163a";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--3f24f";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--7ebdc";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--de99e";
export var row = "Testimonials-module--row--3ed29";
export var section = "Testimonials-module--section--4eedb";
export var staffRec = "Testimonials-module--staffRec--8e6f1";
export var testName = "Testimonials-module--testName--3f59e";
export var testimonials = "Testimonials-module--testimonials--0d0bd";
export var testimonialsContent = "Testimonials-module--testimonialsContent--a0c1b";
export var vector2 = "Testimonials-module--vector2--b728b";