// extracted by mini-css-extract-plugin
export var attachment = "saasDeveloperForm-module--attachment--2408d";
export var attachmentLabel = "saasDeveloperForm-module--attachmentLabel--aff95";
export var errorTxt = "saasDeveloperForm-module--errorTxt--1741c";
export var fieldColor = "saasDeveloperForm-module--fieldColor--90f60";
export var formControl = "saasDeveloperForm-module--form-control--ce216";
export var formInner = "saasDeveloperForm-module--formInner--4d108";
export var hr = "saasDeveloperForm-module--hr--cb0e9";
export var newlinks = "saasDeveloperForm-module--newlinks--d1394";
export var query = "saasDeveloperForm-module--query--43251";
export var salesFormCheckBoxSize = "saasDeveloperForm-module--salesFormCheckBoxSize--e9ae7";
export var salesFormRowHeight = "saasDeveloperForm-module--salesFormRowHeight--70851";