import React from "react"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import * as styles from "./Boost.module.scss"

const Ready = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <Container className={styles.con}>
        <div className={styles.Ready}>
          <div
            className={styles.description2}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle2,
            }}
          />
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className={`${styles.bannerBtn}`}>
            {strapiData?.buttons[0] && (
              <Link
                to={`/${strapiData?.buttons[0]?.url}/`}
                className={styles.btn_white2_border}
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Ready
