import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Launch.module.scss"

const Banner = ({ strapiData }) => {
  return (
    <div className={styles.Launch}>
      <Container>
        <div className={styles.heading}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <Row>
          {strapiData?.cards?.map((e, i) => (
            <Col
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className={` gap-30 ${styles.warper}`}
              key={i}
            >
              <lottie-player
                autoplay
                loop
                src={e?.image1[0]?.localFile?.publicURL}
                style={{ height: "60px", margin: "0 auto" }}
              />
              <h3>{e?.title}</h3>
              <p>{e?.subTitle}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Banner
