// extracted by mini-css-extract-plugin
export var description = "TechStacksMobile-module--description--44dc4";
export var globalTechMobile = "TechStacksMobile-module--globalTechMobile--aa41e";
export var heading = "TechStacksMobile-module--heading--7c7fe";
export var name = "TechStacksMobile-module--name--5ca75";
export var name1 = "TechStacksMobile-module--name1--cb87d";
export var name3 = "TechStacksMobile-module--name3--fdc53";
export var name5 = "TechStacksMobile-module--name5--ce0e5";
export var name7 = "TechStacksMobile-module--name7--a1963";
export var name9 = "TechStacksMobile-module--name9--e94bc";
export var techMobile = "TechStacksMobile-module--techMobile--e515c";
export var techMobileSaasDev = "TechStacksMobile-module--techMobileSaasDev--84b36";