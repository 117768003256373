// extracted by mini-css-extract-plugin
export var description = "TechStacks-module--description--32951";
export var frame = "TechStacks-module--frame--fa753";
export var heading = "TechStacks-module--heading--44e7c";
export var name = "TechStacks-module--name--98653";
export var name1 = "TechStacks-module--name1--cdbdb";
export var name3 = "TechStacks-module--name3--42524";
export var name5 = "TechStacks-module--name5--ef496";
export var name7 = "TechStacks-module--name7--cb00d";
export var name9 = "TechStacks-module--name9--0aabe";
export var stacks = "TechStacks-module--stacks--e2a8c";
export var tech = "TechStacks-module--tech--c0f72";
export var techStacks = "TechStacks-module--techStacks--ad829";