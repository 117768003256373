import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./Choose.module.scss"

const Choose = ({ strapiData }) => {
  return (
    <div className={styles.Choose}>
      <Container>
        <div className={styles.heading}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <Row className={` gap-30 justify-content-center ${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <Col lg={3} md={6} sm={12} xs={12} key={i}>
              <div className={styles.cards}>
                <lottie-player
                  autoplay
                  loop
                  src={e?.image1[0]?.localFile?.publicURL}
                  style={{ height: "60px", width: "60px", margin: "auto" }}
                />
                <Link to={e?.buttons[0]?.url}>
                  <h3>{e?.title}</h3>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
        <Row
          className={` gap-30 justify-content-center ${styles.cardWrapper2}`}
        >
          {strapiData?.cards?.map((e, i) => (
            <Col lg={3} md={6} sm={12} xs={12}>
              <div className={i === 0 ? styles.firstMobileCard : styles.cards2}>
                <div className={` d-flex align-items-center `}>
                  <lottie-player
                    autoplay
                    loop
                    src={e?.image1[0]?.localFile?.publicURL}
                    style={{ height: "60px", width: "60px", margin: "0px" }}
                  />
                  <Link to={e?.buttons[0]?.url}>
                    <h3>{e?.title}</h3>
                  </Link>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Choose
