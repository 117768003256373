// extracted by mini-css-extract-plugin
export var aurther = "Testimonials2-module--aurther--ae1d1";
export var circleTes = "Testimonials2-module--circleTes--7d732";
export var content = "Testimonials2-module--content--1dd9d";
export var des = "Testimonials2-module--des--556a7";
export var iconContainer = "Testimonials2-module--iconContainer--b60d8";
export var iconContainerLeft = "Testimonials2-module--iconContainerLeft--6b4f3";
export var personBg = "Testimonials2-module--personBg--3463f";
export var portfolioArrowIcon = "Testimonials2-module--portfolioArrowIcon--8ca49";
export var portfolioArrowIconCover = "Testimonials2-module--portfolioArrowIconCover--5cee4";
export var portfolioArrowRightIconCover = "Testimonials2-module--portfolioArrowRightIconCover--5bb46";
export var row = "Testimonials2-module--row--75057";
export var section = "Testimonials2-module--section--85863";
export var tesBtn = "Testimonials2-module--tesBtn--616b8";
export var tesDots = "Testimonials2-module--tesDots--f0029";
export var tesRec = "Testimonials2-module--tesRec--20e64";
export var testimonials = "Testimonials2-module--testimonials--c7f89";